<script lang="ts" setup>
import { STATUS_SYSTEM_COLOR_DICTIONARY } from "~/utils/statusSystemPrizes";

const { open } = useFunrizeModals();
const loginGuard = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { data: tournamentData } = useGetTournamentData();
const { handleSubscribe } = useTournamentSubscribe({
	id: tournamentData.value?.data?.id,
	isSubscribed: tournamentData.value?.data?.isSubscribed
});
const { gameOfWeek } = useHomePage({ immediate: false });
const { handleOpenGame } = useOpenGame(open);
const isGuest = useIsGuest();
const localPath = useLocalePath();
const { questData, dayPrizePool, isFreeSpinQuest, isAdventQuest, questPrizeSum } = useGameQuest();
const { isCanPlay } = useBingoData({ immediate: false });
const { isSweepStakes } = useSwitchMode();

const isSeasonEnabled = computed(() => appInitData.value?.season?.isEnabled && !isGuest.value);
const rankLeagueType = computed(() => appInitData.value?.rankLeague?.progressData?.section?.type || "bronze");
const bgColor = computed(() =>
	isGuest.value ? "var(--gyumri)" : `var(${STATUS_SYSTEM_COLOR_DICTIONARY[rankLeagueType.value]})`
);
const showAdventQuest = computed(() => appInitData.value?.gameMode === "SweepStakes" && isAdventQuest.value);
const isShowRaces = computed(() => !isSeasonEnabled.value && tournamentData.value?.data && !showAdventQuest.value);

const handleClickOpenGame = () => {
	if (gameOfWeek.value?.isExclusive && !isSweepStakes.value) {
		open("LazyOModalGamePreviewExclusive", {
			gameId: gameOfWeek.value.id
		});

		return;
	}

	handleOpenGame(gameOfWeek.value?.slug, "gameplay");
};

const handleJoinRace = () => {
	loginGuard({
		success: () => {
			if (!tournamentData.value?.data?.isActive) {
				navigateTo(localPath("/races/"));
				return;
			}

			handleSubscribe(tournamentData.value?.data?.id);
			open("LazyOModalRacesGames");
		},
		fail: () => open("LazyOModalSignup")
	});
};

const handleBingo = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			navigateTo(localPath("/issues/popular-games/"));
		},
		fail: () => open("LazyOModalSignup")
	});
};

const handleFreeSpin = () => {
	loginGuard({
		success: () => {
			if (questData.value?.questInfo?.isAvailable) {
				open("LazyOModalFreeSpin");
				return;
			}
			open("LazyOModalFreeSpinAlternative");
		},
		fail: () => open("LazyOModalSignup")
	});
};

const handleClickSeason = () => {
	loginGuard({
		success: () => {
			if (!appInitData.value?.season?.isAvailable) {
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
				return;
			}
			open("LazyOModalSeasonTower");
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleClickAdvent = () => {
	open("LazyOModalAdventCalendar");
};
</script>
<template>
	<div id="activities" :class="['activities text-cannes', { 'without-race': !tournamentData?.data }]">
		<MHomeActivityWeekOfGame class="item" :game-of-week="gameOfWeek" @click="handleClickOpenGame" />
		<MHomeActivityBingo v-if="appInitData?.bingo?.isActive && !isGuest" class="item" @click="handleBingo" />
		<MHomeActivityRace
			v-else-if="!!isShowRaces"
			class="item"
			:race="tournamentData.data"
			:is-sweep-stakes="appInitData?.gameMode === 'SweepStakes'"
			@click="handleJoinRace"
		/>

		<MHomeActivitySeason
			v-else-if="isSeasonEnabled"
			class="item"
			:is-available="appInitData?.season?.isAvailable"
			:finished-at="appInitData?.season?.finishedAt"
			@click="handleClickSeason"
		/>
		<MHomeActivityAdvent
			v-else-if="showAdventQuest"
			class="item"
			:title="questData?.questInfo?.title"
			:prize="questPrizeSum"
			:finished-at="questData?.questInfo?.end"
			@click="handleClickAdvent"
		/>
		<MHomeActivityFreeSpin
			v-if="isFreeSpinQuest"
			class="item"
			:is-completed="questData?.dayInfo?.isCompleted"
			:prize-pool="dayPrizePool"
			:finished-at="questData?.questInfo?.end"
			@click="handleFreeSpin"
		/>
		<OHomeActivityWheel
			v-else
			:class="['item', { 'full-width': !tournamentData?.data }]"
			:rank-league-type="rankLeagueType"
		/>
	</div>
</template>
<style lang="scss" scoped>
.activities {
	--a-badge-skew-padding: 0 8px;

	display: flex;
	justify-content: space-between;
	gap: gutter(3);
	margin-bottom: gutter(3);

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(2.5);
		flex-wrap: wrap;
		gap: gutter(1);

		&.without-race {
			.item {
				width: 100%;
				height: 170px;
			}
		}
	}
}

.item {
	width: 50%;
	height: 170px;
	border-radius: 12px;
	background: v-bind(bgColor);
	cursor: pointer;
	padding: gutter(4.375) 0 gutter(2.5) gutter(1.5);
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		width: calc(50% - 4px);
		height: 238px;

		&.game-week {
			width: 100%;
			height: 170px;
		}
	}
}
</style>
